import _ from "lodash";
import { Utils } from "revlock-webutils";
import { itemHasReasonCode } from "./AccountingUtils";
import _StandalonePricePolicy from "./StandalonePricePolicy";
import ItemType from "./reference/ItemType";

const {
    formatPercentage: _formatPercentage,
    formatCurrency: _formatCurrency,
    formatLongStandardDate
} = Utils;

const formatCurrency = (amount, currency) =>
    _formatCurrency(amount, undefined, undefined, undefined, currency || "$");

const formatPercentage = (value) => _formatPercentage(value * 100);
const percentRangeFormat = ({ min, max }) =>
    `${formatPercentage(min)} - ${formatPercentage(max)}`;
const priceRangeFormat = ({ min, max }, currency) =>
    `${formatCurrency(min, currency)} - ${formatCurrency(max, currency)}`;

export const CalculationLabels = (currencySymbol) => ({
    //Title: Product Name
    // Contract
    // product name and product code (From ui, 0 and 1 is the order)
    quantity: {
        label: ["Units Sold", "Quantity"],
        group: "Contract",
        order: 2,
        format: (value) => value
    }, // Units Sold
    salePrice: {
        label: ["Actual Sale Price"],
        group: "Contract",
        order: 3,
        format: (value) =>
            Utils.formatCurrency(
                value,
                undefined,
                undefined,
                undefined,
                currencySymbol
            )
    }, // Unit Price (if all are using actualSalePrice)
    listPrice: {
        label: ["List Price"],
        group: "Contract",
        order: 4,
        format: (value) =>
            Utils.formatCurrency(
                value,
                undefined,
                undefined,
                undefined,
                currencySymbol
            )
    },
    startDate: {
        label: ["Start Date"],
        group: "Contract",
        order: 5,
        format: formatLongStandardDate
    },
    endDate: {
        label: ["End Date"],
        group: "Contract",
        order: 6,
        format: formatLongStandardDate
    },
    duration: {
        label: ["Duration"],
        group: "Contract",
        order: 7,
        format: (value) => value
    },

    // SSP Config
    sspType: {
        label: ["Rule Type"],
        group: "SSP Config",
        order: 0,
        format: (value) => {
            if (value == "AS_IS" || value == "STANDALONE_PRICE") {
                return "Sale Price";
            } else if (value == "LIST_PRICE") {
                return "List Price";
            } else if (value == "STANDALONE") {
                return "Standalone";
            } else if (value == "DISCOUNT_RANGE") {
                return "Discount";
            } else if (value == "RANGE" || value == "NUMERIC") {
                return "Dollar Amount";
            } else if (value == "RESIDUAL") {
                return "Residual";
            } else if (value == "SIMPLE_PERCENT_NET_RANGE") {
                return "Simple Percent Net";
            } else if (value == "PERCENT_NET_RANGE") {
                return "Apportioned Percent Net";
            }

            return value;
        }
    },
    discountRange: {
        label: ["Discount Range"],
        group: "SSP Config",
        order: 1,
        format: percentRangeFormat
    },
    percentRange: {
        label: ["Percent Range"],
        group: "SSP Config",
        order: 2,
        format: percentRangeFormat
    },
    priceRange: {
        label: ["Price Range"],
        group: "SSP Config",
        order: 3,
        format: (value) => priceRangeFormat(value, currencySymbol)
    },
    strategy: {
        label: ["Selector"],
        group: "SSP Config",
        order: 4,
        format: (strategy) => SSPValueMethod[strategy]
    },

    // Calculations
    supportLineItemCount: {
        label: ["Support Item Count"],
        group: "Calculations",
        order: 0,
        format: (value) => value
    },
    fullSupportDuration: {
        label: ["Full Support Duration (months)"],
        group: "Calculations",
        order: 1,
        format: (value) => value
    },
    totalSupportSalePrice: {
        label: ["Total Support Sale Price", "Total Support Sale Amount"],
        group: "Calculations",
        order: 2,
        format: (value) =>
            Utils.formatCurrency(
                value,
                undefined,
                undefined,
                undefined,
                currencySymbol
            )
    }, // Total Support Sale Price (label)
    supportPriceProportion: {
        label: ["Support Price Proportion"],
        group: "Calculations",
        order: 8,
        format: formatPercentage
    },
    totalAnnualSupportSalePrice: {
        label: [
            "Total Support Sale Price",
            "Total Support Sale Price (Annual)"
        ],
        group: "Calculations",
        order: 3,
        format: (value) =>
            Utils.formatCurrency(
                value,
                undefined,
                undefined,
                undefined,
                currencySymbol
            )
    },
    totalAnnualLicensedSalePrice: {
        label: ["Total License Sale Amount (Annual)"],
        group: "Calculations",
        order: 4,
        format: (value) =>
            Utils.formatCurrency(
                value,
                undefined,
                undefined,
                undefined,
                currencySymbol
            )
    }, // Total License Sale Price
    totalLicensedSalePrice: {
        label: ["Total License Sale Amount"],
        group: "Calculations",
        order: 5,
        format: (value) =>
            Utils.formatCurrency(
                value,
                undefined,
                undefined,
                undefined,
                currencySymbol
            )
    }, // Total License Sale Price
    totalExtendedNonResidualSSP: {
        label: ["Total Non Residual SSP"],
        group: "Calculations",
        order: 6,
        format: (value) => value
    },
    totalExtendedResidualSalePrice: {
        label: ["Total Residual Sale Price"],
        group: "Calculations",
        order: 7,
        format: (value) => value
    },
    residualSalePriceProportion: {
        label: ["Residual Sale Price Proportion"],
        group: "Calculations",
        order: 8,
        format: (value) => value
    },
    totalExtendedResidualSSP: {
        label: ["Total Residual SSP"],
        group: "Calculations",
        order: 9,
        format: (value) => value
    },

    // Standalone Price
    standalonePrice: {
        label: ["Standalone Selling Price"],
        group: "Standalone Price",
        order: 0,
        format: (value, itemWithReasonCode) => {
            return Utils.formatCurrency(
                itemWithReasonCode ? 0.0 : value,
                undefined,
                undefined,
                undefined,
                currencySymbol
            );
        }
    }
});

export const SSPValueMethod = {
    midpoint: "Midpoint",
    boundary: "Boundary",
    min: "Minimum",
    max: "Maximum"
};

function getSSPFromRange(standalonePrice, min, max, salePrice, calculation) {
    if (
        standalonePrice["min"] == undefined ||
        standalonePrice["max"] == undefined ||
        standalonePrice["strategy"] == undefined
    ) {
        throw new Error(
            "Invalid standalonePrice " +
                JSON.stringify(standalonePrice) +
                ". Required attribute missing (min || max || strategy)."
        );
    }

    calculation.priceRange = { min, max };
    calculation.strategy = standalonePrice["strategy"];

    // If salePrice is in range.
    if (min <= salePrice && salePrice <= max) {
        calculation.standalonePrice = salePrice;
        return salePrice;
    }

    let allStrategies = ["midpoint", "boundary", "min", "max"];
    if (!allStrategies.includes(standalonePrice["strategy"])) {
        throw new Error(
            "Unknow strategy [" +
                standalonePrice["strategy"] +
                "] for StandAlonePrice selector. Accepted values " +
                JSON.stringify(allStrategies)
        );
    }

    let toReturn = 0;

    switch (standalonePrice["strategy"]) {
        case "midpoint":
            toReturn = min + (max - min) / 2;
            break;
        case "min":
            toReturn = min;
            break;
        case "max":
            toReturn = max;
            break;
        case "boundary":
            if (salePrice > max) {
                toReturn = max;
            } else {
                toReturn = min;
            }
            break;
    }

    calculation.standalonePrice = toReturn;
    return toReturn;
}

export const StandalonePricePolicy = _StandalonePricePolicy;

const getLicenseProductTypes = (licenseProductType) => {
    let toReturn = new Set();
    licenseProductType.split(",").forEach((type) => {
        toReturn.add(type.trim());
    });

    return toReturn;
};

const computeTermSSP = (
    salesOrder,
    salesOrderItem,
    standalonePrice,
    revenueArrangementItem
) => {
    const RevenueArrangementHelper = require("./RevenueArrangementHelper");

    let startDate = salesOrderItem.startDate;
    // for an unrecognized item, start date should be the modification date
    if (
        revenueArrangementItem &&
        revenueArrangementItem.itemType == ItemType.SPLIT_UNRECOGNIZED
    ) {
        startDate = salesOrder.modificationDate;
    }
    let endDate = salesOrderItem.endDate;

    if (Utils.isNumber(salesOrderItem.product.listPricePeriod)) {
        return RevenueArrangementHelper.computeTermAmount(
            salesOrderItem.product,
            startDate,
            endDate,
            standalonePrice
        );
    } else {
        return standalonePrice;
    }
};

export const getNonCreditNoteLineItems = (
    revenueArrangementItem,
    orgConfig
) => {
    return revenueArrangementItem.filter(
        (rai) =>
            !rai.salesOrderItem.referenceLineItemId ||
            (rai.salesOrderItem.referenceLineItemId &&
                rai.ssp.standalonePrice != undefined &&
                rai.ssp.standalonePrice.type == "AS_IS") ||
            (rai.salesOrderItem.referenceLineItemId &&
                itemHasReasonCode(rai, orgConfig))
    );
};

export const getCreditNoteLineItems = (revenueArrangementItem, orgConfig) => {
    return revenueArrangementItem.filter(
        (rai) =>
            rai.salesOrderItem.referenceLineItemId &&
            rai.ssp.standalonePrice != undefined &&
            rai.ssp.standalonePrice.type != "AS_IS" &&
            !itemHasReasonCode(rai, orgConfig)
    );
};

const getSupportPercentNetProductValue = (standalonePrice, soi) => {
    const relatedPercentNetProductKey =
        (standalonePrice && standalonePrice.relatedPercentNetProductKey) ||
        "productType";
    let supportPercentNetProductValue;
    if (relatedPercentNetProductKey) {
        if (
            relatedPercentNetProductKey == "productCode" ||
            relatedPercentNetProductKey == "code"
        ) {
            supportPercentNetProductValue = soi.product.code;
        } else {
            supportPercentNetProductValue =
                (soi.attributes &&
                    soi.attributes[relatedPercentNetProductKey]) ||
                (soi.product.attributes &&
                    soi.product.attributes[relatedPercentNetProductKey]);
        }
    }
    return supportPercentNetProductValue;
};

const SSPExtractor = {
    RANGE: (salesOrder, revenueArrangement, revenueArrangementItem) => {
        let standalonePrice = revenueArrangementItem.ssp.standalonePrice;
        let listPrice = revenueArrangementItem.salesOrderItem.listPrice;
        let salePrice = revenueArrangementItem.salesOrderItem.actualSalePrice;

        let inverse = salePrice < 0 || listPrice < 0;

        const { salesOrderItem } = revenueArrangementItem;

        // Compute term min and max SSP. SSP is always given for product's listPricePeriod and listPricePeriodUnit.
        let min = computeTermSSP(
            salesOrder,
            salesOrderItem,
            standalonePrice.min,
            revenueArrangementItem
        );
        let max = computeTermSSP(
            salesOrder,
            salesOrderItem,
            standalonePrice.max,
            revenueArrangementItem
        );

        if (inverse) {
            // it seems that sale price is -ve. Inversing salePrice coz they are +ve
            salePrice = Math.abs(salePrice);
        }

        revenueArrangementItem.sspCalculation = {
            sspType: "RANGE",
            priceRange: { min, max },
            salePrice,
            quantity: revenueArrangementItem.salesOrderItem.quantity
        };

        let toReturn = getSSPFromRange(
            standalonePrice,
            min,
            max,
            salePrice,
            revenueArrangementItem.sspCalculation
        );

        if (inverse) {
            toReturn *= -1;
            revenueArrangementItem.sspCalculation.standalonePrice = toReturn;
            revenueArrangementItem.sspCalculation.salePrice *= -1;

            revenueArrangementItem.sspCalculation.priceRange.max *= -1;
            revenueArrangementItem.sspCalculation.priceRange.min *= -1;
        }

        return toReturn;
    },

    AS_IS: (salesOrder, revenueArrangement, revenueArrangementItem) => {
        let salePrice = revenueArrangementItem.salesOrderItem.actualSalePrice;

        revenueArrangementItem.sspCalculation = {
            sspType: "AS_IS",
            salePrice: salePrice,
            standalonePrice: salePrice,
            quantity: revenueArrangementItem.salesOrderItem.quantity
        };

        return salePrice;
    },

    LIST_PRICE: (salesOrder, revenueArrangement, revenueArrangementItem) => {
        let listPrice = revenueArrangementItem.salesOrderItem.listPrice;

        revenueArrangementItem.sspCalculation = {
            sspType: "LIST_PRICE",
            salePrice: listPrice,
            standalonePrice: listPrice,
            quantity: revenueArrangementItem.salesOrderItem.quantity
        };

        return listPrice;
    },

    STANDALONE: (salesOrder, revenueArrangement, revenueArrangementItem) => {
        let salePrice = revenueArrangementItem.salesOrderItem.actualSalePrice;

        revenueArrangementItem.sspCalculation = {
            sspType: "STANDALONE",
            salePrice: salePrice,
            quantity: revenueArrangementItem.salesOrderItem.quantity
        };

        return salePrice;
    },
    DISCOUNT_RANGE: (
        salesOrder,
        revenueArrangement,
        revenueArrangementItem
    ) => {
        let {
            startDate,
            endDate,
            quantity,
            actualSalePrice: salePrice,
            listPrice
        } = revenueArrangementItem.salesOrderItem;

        let standalonePrice = revenueArrangementItem.ssp.standalonePrice;

        let min =
            standalonePrice.max != undefined &&
            listPrice * (1 - standalonePrice.max);
        let max =
            standalonePrice.min != undefined &&
            listPrice * (1 - standalonePrice.min);

        let inverse = salePrice < 0 || listPrice < 0;

        if (inverse) {
            salePrice = Math.abs(salePrice);
            min = Math.abs(min);
            max = Math.abs(max);
        }

        revenueArrangementItem.sspCalculation = {
            startDate,
            endDate,
            sspType: "DISCOUNT_RANGE",
            discountRange: {
                min: standalonePrice.min,
                max: standalonePrice.max
            },
            priceRange: { min, max },
            salePrice,
            listPrice,
            quantity
        };

        let toReturn = getSSPFromRange(
            standalonePrice,
            min,
            max,
            salePrice,
            revenueArrangementItem.sspCalculation
        );

        if (inverse) {
            toReturn *= -1;
            revenueArrangementItem.sspCalculation.standalonePrice = toReturn;

            revenueArrangementItem.sspCalculation.priceRange.min *= -1;
            revenueArrangementItem.sspCalculation.priceRange.max *= -1;

            revenueArrangementItem.sspCalculation.salePrice *= -1;
        }

        return toReturn;
    },

    SIMPLE_PERCENT_NET_RANGE: (
        salesOrder,
        _revenueArrangement,
        revenueArrangementItem,
        orgConfig
    ) => {
        let calculation = {
            sspType: "SIMPLE_PERCENT_NET_RANGE"
        };

        let revenueArrangement = Utils.cloneDeep(_revenueArrangement);

        // by default I always want items that don't have reason code
        revenueArrangement.revenueArrangementItem = _revenueArrangement.revenueArrangementItem.filter(
            (rai) => {
                // credit note line item is determined if we have referenceLineItemId
                // + that line is not AS_IS
                if (
                    !itemHasReasonCode(rai, orgConfig) &&
                    (!rai.salesOrderItem.referenceLineItemId ||
                        (rai.salesOrderItem.referenceLineItemId &&
                            rai.ssp.standalonePrice != undefined &&
                            rai.ssp.standalonePrice.type == "AS_IS"))
                ) {
                    return rai;
                }
            }
        );

        if (itemHasReasonCode(revenueArrangementItem, orgConfig)) {
            const isPurchaseOrderLineItem =
                (revenueArrangementItem.salesOrderItem.parentSalesOrderItemId &&
                    true) ||
                false;
            // incoming item itself has a reason code, so just give me the items which have reason code
            // as, I don't want to re-allocate to non-reason code items.
            revenueArrangement.revenueArrangementItem = _revenueArrangement.revenueArrangementItem.filter(
                (rai) => {
                    if (itemHasReasonCode(rai, orgConfig)) {
                        return rai;
                    }
                }
            );

            if (isPurchaseOrderLineItem) {
                // we only want the ones for which parent item is same
                revenueArrangement.revenueArrangementItem = revenueArrangement.revenueArrangementItem.filter(
                    (rai) => {
                        if (
                            revenueArrangementItem.salesOrderItem
                                .parentSalesOrderItemId ==
                                rai.salesOrderItem.parentSalesOrderItemId ||
                            revenueArrangementItem.salesOrderItem
                                .parentSalesOrderItemId == rai.salesOrderItem.id
                        ) {
                            // all items of the same group
                            return rai;
                        }
                    }
                );
            }
        }

        revenueArrangementItem.sspCalculation = calculation;

        let {
            startDate,
            endDate,
            duration,
            extendedSalePrice,
            quantity,
            actualSalePrice,
            extendedListPrice
        } = revenueArrangementItem.salesOrderItem;

        let standalonePrice = revenueArrangementItem.ssp.standalonePrice;

        let licenseProductType =
            (standalonePrice &&
                standalonePrice.licenseProductType &&
                getLicenseProductTypes(standalonePrice.licenseProductType)) ||
            new Set(["License"]);

        const raiSoi = revenueArrangementItem.salesOrderItem;

        let supportPercentNetProductValue = getSupportPercentNetProductValue(
            standalonePrice,
            raiSoi
        );

        if (!supportPercentNetProductValue) {
            supportPercentNetProductValue = "Support";
        }

        let totalLicensedSalePrice = 0;
        let totalSupportSalePrice = 0;
        let hasNonZeroSupportItem = false;
        let allSupportItems = [];

        revenueArrangement.revenueArrangementItem.forEach((rai) => {
            let { salesOrderItem, ssp: _ssp } = rai;

            let relatedSOIPercentNetProductKey = getSupportPercentNetProductValue(
                standalonePrice,
                salesOrderItem
            );

            if (
                relatedSOIPercentNetProductKey &&
                licenseProductType.has(relatedSOIPercentNetProductKey)
            ) {
                // Use extended sale price here to ensure that we capture the salePrice of complete order
                // when calculating.
                totalLicensedSalePrice += salesOrderItem.extendedSalePrice;
            } else if (
                relatedSOIPercentNetProductKey ===
                    supportPercentNetProductValue &&
                Utils.isObject(_ssp.standalonePrice) &&
                _ssp.standalonePrice.type == "SIMPLE_PERCENT_NET_RANGE"
            ) {
                totalSupportSalePrice += salesOrderItem.extendedSalePrice;

                hasNonZeroSupportItem =
                    hasNonZeroSupportItem ||
                    salesOrderItem.extendedSalePrice != 0;

                allSupportItems.push(rai);
            }
        });

        calculation.totalLicensedSalePrice = totalLicensedSalePrice;
        calculation.totalSupportSalePrice = totalSupportSalePrice;
        calculation.supportLineItemCount = allSupportItems.length;

        if (totalLicensedSalePrice == 0) {
            // If there are no products in current salesOrder which are license products, there is no reallocation.
            return actualSalePrice;
        }

        if (hasNonZeroSupportItem) {
            calculation.supportPriceProportion =
                extendedSalePrice / totalSupportSalePrice;
        } else {
            calculation.supportPriceProportion = 1 / allSupportItems.length;
        }

        if (!Number.isFinite(calculation.supportPriceProportion)) {
            // we have have / 0 error (Inifinite)
            calculation.supportPriceProportion = 0;
        }

        let inverse = false;
        if (totalLicensedSalePrice < 0) {
            // it seems that license sale price is -ve. Inversing the min and max coz they are -ve too
            inverse = true;
            totalLicensedSalePrice = Math.abs(totalLicensedSalePrice);
            totalSupportSalePrice = Math.abs(totalSupportSalePrice);
        }

        let min = totalLicensedSalePrice * standalonePrice.min;
        let max = totalLicensedSalePrice * standalonePrice.max;

        calculation.percentRange = {
            min: standalonePrice.min,
            max: standalonePrice.max
        };
        calculation.quantity = quantity;

        let totalSupportSSP = getSSPFromRange(
            standalonePrice,
            min,
            max,
            calculation.totalSupportSalePrice,
            calculation
        );

        let toReturn = totalSupportSSP * calculation.supportPriceProportion;

        if (inverse) {
            totalLicensedSalePrice = totalLicensedSalePrice * -1;
            totalSupportSalePrice = totalSupportSalePrice * -1;

            toReturn *= -1;

            calculation.priceRange.min *= -1;
            calculation.priceRange.max *= -1;

            calculation.percentRange.min *= -1;
            calculation.percentRange.max *= -1;
        }

        calculation.standalonePrice = toReturn;

        Object.assign(calculation, {
            startDate,
            endDate,
            duration,
            salePrice: extendedSalePrice,
            standalonePrice: toReturn,
            listPrice: extendedListPrice
        });

        toReturn /= quantity;
        return toReturn;
    },

    PERCENT_NET_RANGE: (
        salesOrder,
        _revenueArrangement,
        revenueArrangementItem,
        orgConfig
    ) => {
        let standalonePrice = revenueArrangementItem.ssp.standalonePrice;

        const { runtime } = salesOrder;

        let calculation = { sspType: "PERCENT_NET_RANGE" };

        let revenueArrangement = Utils.cloneDeep(_revenueArrangement);

        // by default I always want items that don't have reason code and that are not credit notes.
        revenueArrangement.revenueArrangementItem = _revenueArrangement.revenueArrangementItem.filter(
            (rai) => {
                // credit note line item is determined if we have referenceLineItemId
                // + that line is not AS_IS
                if (
                    !itemHasReasonCode(rai, orgConfig) &&
                    (!rai.salesOrderItem.referenceLineItemId ||
                        (rai.salesOrderItem.referenceLineItemId &&
                            rai.ssp.standalonePrice != undefined &&
                            rai.ssp.standalonePrice.type == "AS_IS"))
                ) {
                    return rai;
                }
            }
        );

        if (itemHasReasonCode(revenueArrangementItem, orgConfig)) {
            const isPurchaseOrderLineItem =
                (revenueArrangementItem.salesOrderItem.parentSalesOrderItemId &&
                    true) ||
                false;

            // incoming item itself has a reason code, so just give me the items which have reason code
            // as, I don't want to re-allocate to non-reason code items.
            revenueArrangement.revenueArrangementItem = _revenueArrangement.revenueArrangementItem.filter(
                (rai) => {
                    if (itemHasReasonCode(rai, orgConfig)) {
                        return rai;
                    }
                }
            );

            if (isPurchaseOrderLineItem) {
                // we only want the ones for which parent item is same, these are all items which have reason codes
                // set on them
                revenueArrangement.revenueArrangementItem = revenueArrangement.revenueArrangementItem.filter(
                    (rai) => {
                        if (
                            revenueArrangementItem.salesOrderItem
                                .parentSalesOrderItemId ==
                                rai.salesOrderItem.parentSalesOrderItemId ||
                            revenueArrangementItem.salesOrderItem
                                .parentSalesOrderItemId == rai.salesOrderItem.id
                        ) {
                            return rai;
                        }
                    }
                );
            }
        }

        revenueArrangementItem.sspCalculation = calculation;

        let {
            startDate,
            endDate,
            duration,
            extendedSalePrice,
            quantity,
            actualSalePrice,
            extendedListPrice
        } = revenueArrangementItem.salesOrderItem;

        let licenseProductType =
            (standalonePrice &&
                standalonePrice.licenseProductType &&
                getLicenseProductTypes(standalonePrice.licenseProductType)) ||
            new Set(["License"]);

        const raiSoi = revenueArrangementItem.salesOrderItem;
        let supportPercentNetProductValue = getSupportPercentNetProductValue(
            standalonePrice,
            raiSoi
        );

        if (!supportPercentNetProductValue) {
            supportPercentNetProductValue = "Support";
        }

        let totalAnnualLicensedSalePrice = 0;
        let totalSupportSalePrice = 0;

        let allSupportItems = [];

        revenueArrangement.revenueArrangementItem.forEach((rai) => {
            let { salesOrderItem, ssp: _ssp } = rai;

            let relatedSOIPercentNetProductKey = getSupportPercentNetProductValue(
                standalonePrice,
                salesOrderItem
            );

            if (
                relatedSOIPercentNetProductKey &&
                licenseProductType.has(relatedSOIPercentNetProductKey)
            ) {
                // Use extended sale price here to ensure that we capture the salePrice of 0plete order
                // when calculating.
                totalAnnualLicensedSalePrice +=
                    salesOrderItem.annualExtendedSalePrice;
            } else if (
                relatedSOIPercentNetProductKey ===
                    supportPercentNetProductValue &&
                Utils.isObject(_ssp.standalonePrice) &&
                _ssp.standalonePrice.type == "PERCENT_NET_RANGE"
            ) {
                totalSupportSalePrice += salesOrderItem.extendedSalePrice;
                allSupportItems.push(rai);
            }
        });

        calculation.totalAnnualLicensedSalePrice = totalAnnualLicensedSalePrice;
        calculation.totalSupportSalePrice = totalSupportSalePrice;
        calculation.supportLineItemCount = allSupportItems.length;

        if (totalAnnualLicensedSalePrice == 0) {
            // If there are no products in current salesOrder which are license products, there is no reallocation.
            return actualSalePrice;
        }

        if (!Utils.shareRuntime(salesOrder, revenueArrangementItem)) {
            runtime.monthlySalePrice = populateMonthlySalePrice({
                revenueArrangementItems: allSupportItems,
                totalSupportSalePrice
            });
            runtime.fullSupportDuration = Object.keys(
                runtime.monthlySalePrice
            ).length;
            runtime.totalAnnualSupportSalePrice =
                (totalSupportSalePrice / runtime.fullSupportDuration) * 12;
        }

        calculation.fullSupportDuration = runtime.fullSupportDuration;
        calculation.totalAnnualSupportSalePrice =
            runtime.totalAnnualSupportSalePrice;

        let inverse = false;
        if (totalAnnualLicensedSalePrice < 0) {
            // it seems that support sale price is -ve. Inversing the min and max coz they are -ve too
            inverse = true;
            totalAnnualLicensedSalePrice = Math.abs(
                totalAnnualLicensedSalePrice
            );
            totalSupportSalePrice = Math.abs(totalSupportSalePrice);

            runtime.totalAnnualSupportSalePrice = Math.abs(
                runtime.totalAnnualSupportSalePrice
            );
        }

        let min = totalAnnualLicensedSalePrice * standalonePrice.min;
        let max = totalAnnualLicensedSalePrice * standalonePrice.max;

        calculation.percentRange = {
            min: standalonePrice.min,
            max: standalonePrice.max
        };
        calculation.quantity = quantity;

        let toReturn = getSSPFromRange(
            standalonePrice,
            min,
            max,
            runtime.totalAnnualSupportSalePrice,
            calculation
        );

        if (!Utils.shareRuntime(salesOrder, revenueArrangementItem)) {
            populateExtendedEstimatedSellingPrice({
                salesOrder,
                revenueArrangementItems: allSupportItems,
                annualSSP: toReturn,
                monthlySalePrice: runtime.monthlySalePrice
            });
        }

        toReturn = revenueArrangementItem.extendedEstimatedSalePrice;

        if (inverse) {
            totalAnnualLicensedSalePrice = totalAnnualLicensedSalePrice * -1;
            totalSupportSalePrice = totalSupportSalePrice * -1;
            runtime.totalAnnualSupportSalePrice =
                runtime.totalAnnualSupportSalePrice * -1;
            toReturn *= -1;

            calculation.priceRange.min *= -1;
            calculation.priceRange.max *= -1;

            calculation.percentRange.min *= -1;
            calculation.percentRange.max *= -1;
        }

        calculation.standalonePrice = toReturn;

        Object.assign(calculation, {
            startDate,
            endDate,
            duration,
            salePrice: extendedSalePrice,
            standalonePrice: toReturn,
            listPrice: extendedListPrice
        });

        toReturn /= quantity;
        return toReturn;
    },

    RESIDUAL: (salesOrder, revenueArrangement, revenueArrangementItem) => {
        let calculation = { sspType: "RESIDUAL" };

        revenueArrangementItem.sspCalculation = calculation;

        let {
            startDate,
            endDate,
            duration,
            extendedSalePrice,
            quantity,
            actualSalePrice
        } = revenueArrangementItem.salesOrderItem;

        // let revenueArrangement = salesOrder.currentRevenueArrangement || salesOrder.newRevenueArrangement

        let sspType = (item) =>
            (item.ssp.standalonePrice &&
                typeof item.ssp.standalonePrice === "object" &&
                item.ssp.standalonePrice.type) ||
            "None";

        let totalExtendedNonResidualSSP = 0;
        let totalExtendedResidualSalePrice = 0;
        let totalExtendedResidualItemCount = 0;
        revenueArrangement.revenueArrangementItem.forEach((item) => {
            // credit note line item is determined if we have referenceLineItemId
            // + that line is not AS_IS
            if (
                item.salesOrderItem.referenceLineItemId &&
                item.ssp.standalonePrice &&
                item.ssp.standalonePrice.type != "AS_IS"
            ) {
                // we don't process credit lines
                return;
            }

            if (sspType(item) != "RESIDUAL") {
                // Non Residual
                totalExtendedNonResidualSSP += item.extendedEstimatedSalePrice;
            } else {
                totalExtendedResidualSalePrice +=
                    item.salesOrderItem.extendedSalePrice;
                totalExtendedResidualItemCount++;
            }
        });

        if (totalExtendedNonResidualSSP == 0) {
            // If there are no products in current salesOrder which are license products, there is no reallocation.
            return actualSalePrice;
        }

        // Total annual extended residual ssp
        let totalExtendedResidualSSP =
            salesOrder.totalBooking - totalExtendedNonResidualSSP;

        /**
         * Rule
         * If contract price is +ve and revenue allocation is < 0 make it 0
         * If contract price is -ve SSP is positive make it 0
         */
        if (salesOrder.totalBooking > 0 && totalExtendedResidualSSP < 0) {
            totalExtendedResidualSSP = 0;
        } else if (
            salesOrder.totalBooking < 0 &&
            totalExtendedResidualSSP > 0
        ) {
            totalExtendedResidualSSP = 0;
        }

        let residualSalePriceProportion = isNaN(
            extendedSalePrice / totalExtendedResidualSalePrice
        )
            ? 0
            : extendedSalePrice / totalExtendedResidualSalePrice;

        if (
            totalExtendedResidualSalePrice == 0 &&
            totalExtendedResidualItemCount == 1
        ) {
            residualSalePriceProportion = 1;
        }

        let toReturn = residualSalePriceProportion * totalExtendedResidualSSP;

        calculation.standalonePrice = toReturn;

        calculation.residualSalePriceProportion = residualSalePriceProportion;

        Object.assign(calculation, {
            startDate,
            endDate,
            duration,
            salePrice: extendedSalePrice,
            standalonePrice: toReturn,
            totalExtendedNonResidualSSP,
            totalExtendedResidualSalePrice,
            residualSalePriceProportion,
            totalExtendedResidualSSP,
            quantity
        });

        toReturn /= quantity;
        return toReturn;
    }
};

export const formatSSPCalculation = (
    calc,
    _CalculationLabels,
    itemWithReasonCode
) => {
    if (!calc) return;

    if (!calc.needFormatting) return calc;

    let toReturn = {};
    Object.keys(calc).forEach((key) => {
        let label =
            (_CalculationLabels[key] && _CalculationLabels[key].label[0]) ||
            key;
        let value = calc[key];
        value =
            (_CalculationLabels[key] &&
                _CalculationLabels[key].format(value, itemWithReasonCode)) ||
            value;
        toReturn[label] = value;
    });

    return toReturn;
};

export function getStandAlonePrice(
    revenueArrangementItem,
    revenueArrangement,
    salesOrder,
    orgConfig
) {
    const { ssp, salesOrderItem } = revenueArrangementItem;

    // Can't think of when this will be the case, we should not need this if check.
    if (typeof ssp === "number") {
        revenueArrangementItem.sspCalculation = {
            standalonePrice: ssp,
            sspType: "NUMERIC",
            needFormatting: true
        };
        return ssp;
    }

    // When SSP is a simple fixed number.
    if (typeof ssp.standalonePrice === "number") {
        revenueArrangementItem.sspCalculation = {
            standalonePrice: ssp.standalonePrice,
            sspType: "NUMERIC",
            needFormatting: true
        };

        // Compute term SSP. SSP is always given for product's listPricePeriod and listPricePeriodUnit.
        return computeTermSSP(
            salesOrder,
            salesOrderItem,
            ssp.standalonePrice,
            revenueArrangementItem
        );
    }

    // Only one item in the sales order, sale price is standalone price.
    if (salesOrder.salesOrderItem.length == 1) {
        let { salePrice } = revenueArrangementItem.salesOrderItem;
        revenueArrangementItem.sspCalculation = {
            standalonePrice: salePrice,
            sspType: "STANDALONE_SALE",
            needFormatting: true
        };
        return salePrice;
    }

    // process more complex SSP policies.
    if (typeof ssp.standalonePrice === "object") {
        const standalonePrice = _getStandAlonePrice(
            salesOrder,
            revenueArrangement,
            revenueArrangementItem,
            orgConfig
        );
        revenueArrangementItem.sspCalculation.needFormatting = true;
        return standalonePrice;
    }
}

function _getStandAlonePrice(
    salesOrder,
    revenueArrangement,
    revenueArrangementItem,
    orgConfig
) {
    let standalonePrice = revenueArrangementItem.ssp.standalonePrice;
    let extractor = SSPExtractor[standalonePrice.type];

    if (extractor) {
        let extracted = extractor(
            salesOrder,
            revenueArrangement,
            revenueArrangementItem,
            orgConfig
        );
        return extracted;
    } else {
        throw new Error(
            "Unknow type [" +
                standalonePrice.type +
                "] for StandAlonePrice selector."
        );
    }
}

function populateMonthlySalePrice({
    revenueArrangementItems,
    totalSupportSalePrice
}) {
    let monthlySalePrice = {};

    revenueArrangementItems.forEach((item) => {
        let { duration, extendedSalePrice } = item.salesOrderItem;

        let start = Utils.getMonthDiff(
            "2000-01-01",
            item.deliveryStartDate,
            true
        );
        let end = start + duration;
        let itemMonthlySalePrice = extendedSalePrice / duration;

        item.uniqueMonthIds = Array.from(
            { length: end - start },
            (el, index) => {
                let _monthId = index + start;
                let monthId = Utils.formatDateCustom(
                    Utils.addMonths("2000-01-01", _monthId),
                    "MMM-YY"
                );

                if (!monthlySalePrice[monthId])
                    monthlySalePrice[monthId] = { total: 0 };

                if (totalSupportSalePrice !== 0) {
                    monthlySalePrice[monthId]["total"] += itemMonthlySalePrice;
                    monthlySalePrice[monthId][item.id] = itemMonthlySalePrice;
                } else {
                    monthlySalePrice[monthId]["total"] += 1;
                    monthlySalePrice[monthId][item.id] = 1;
                }

                return monthId;
            }
        );
    });

    return monthlySalePrice;
}

function populateExtendedEstimatedSellingPrice({
    monthlySalePrice,
    salesOrder,
    revenueArrangementItems,
    annualSSP
}) {
    let monthSSP = annualSSP / 12;

    revenueArrangementItems.forEach((item) => {
        item.extendedEstimatedSalePrice = item.uniqueMonthIds.reduce(
            (ssp, monthId) => {
                let total = monthlySalePrice[monthId]["total"];

                // Zero in total causing NaN SSP value.
                if (total === 0) return ssp;

                return (
                    ssp +
                    (monthlySalePrice[monthId][item.id] / total) * monthSSP
                );
            },
            0
        );

        delete item.uniqueMonthIds;
        Utils.linkRuntime(salesOrder, item);
    });
}
